<template>
  <div>
    <v-toolbar dense flat :class="`eos-scorecard__toolbar--${mode}`">
      <slot name="left"></slot>
      <v-spacer />

      <v-select
        v-model="currentView"
        :items="viewTypes"
        :menu-props="{ 'offset-y': true }"
        dense
        solo
        flat
        hide-details
        class="mr-n3 eos-scorecard__view-type"
        @change="$emit('change-view', $event)"
      />

      <v-tooltip v-if="showResetButton" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="resetMetrics">
            <v-icon>mdi-backup-restore</v-icon>
          </v-btn>
        </template>
        <span>Reset</span>
      </v-tooltip>
      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="addMetric">
            <v-icon>mdi-table-row-plus-after</v-icon>
          </v-btn>
        </template>
        <span>Add a metric</span>
      </v-tooltip>
      <v-tooltip v-if="isEditable && !showMonthlyScores" bottom>
        <template v-slot:activator="{ on }">
          <v-btn  icon v-on="on" @click="addWeek">
            <v-icon>mdi-table-column-plus-after</v-icon>
          </v-btn>
        </template>
        <span>New week</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="ml-n2"  icon v-on="on" @click="exportToCSV">
            <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </template>
        <span>Export CSV</span>
      </v-tooltip>
      <slot name="right"></slot>
    </v-toolbar>

    <div v-if="currentMetrics.length > 0" class="d-flex eos-scorcard__tbl-wrapper" :class="`eos-scorcard__tbl-wrapper--${mode}`">
      <AppDataTable
        class="v-data-table v-data-table--fixed-header theme--light eos-scorecard__tbl eos-scorecard__tbl--no-scroll"
        :class="{ 'eos-scorecard__tbl--shadow-right': showShadow }"
        :items="currentMetrics"
        @pointerenter.native="hoveringOn = 'stickyCols'"
        @pointerleave.native="hoveringOn = null"
      >
        <template v-slot:header>
          <thead class="v-data-table-header">
            <tr>
              <template v-for="(header, index) in headers">
                <th
                  v-if="index < 4"
                  :key="`tbl-sticky-header-${index}`"
                  role="columnheader"
                  scope="col"
                  class="eos-scorecard__header"
                  :class="`text-${header.align}`"
                  :aria-label="header"
                >
                  <span :class="{ 'pl-3': index === 0 }">
                    {{ showMonthlyScores && header.scoreDateIndex >= 0 ? monthHeaders[header.scoreDateIndex] : header.text }}
                  </span>
                </th>
              </template>
            </tr>
          </thead>
        </template>

        <template v-slot:body="{ items }">
          <tbody class="eos-scorecard--disabled">
            <tr
              v-for="(metric) in items"
              :key="metric.name"
              class="eos-scorecard__row eos-scorecard__metric-row"
            >
              <td class="eos-scorecard__metric-title">
                <v-edit-dialog large :return-value.sync="metric.title" @save="onSaveMetric">
                  <div class="d-flex align-center eos-scorecard__metric-title" :title="metric.title">
                    <span class="mr-1">{{ metric.title }}</span>

                    <v-tooltip v-if="metric.description" bottom max-width="300px">
                      <template v-slot:activator="{ on }">
                        <v-icon class="eos-scorecard__metric-goal__info-icon" x-small v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ metric.description }}</span>
                    </v-tooltip>
                  </div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="metric.title"
                      label="Metric name"
                      class="mt-6"
                      dense
                      outlined
                      hide-details
                    />

                    <v-textarea
                      v-model="metric.description"
                      label="Information"
                      class="mt-5"
                      rows="3"
                      dense
                      outlined
                      auto-grow
                      hide-details
                    />
                  </template>
                </v-edit-dialog>
              </td>
              <td>
                <v-edit-dialog large :return-value.sync="metric.owner" @save="onSaveMetric">
                  <AppAvatar
                    v-if="metric.owner"
                    :name="metric.owner"
                    :title="metric.owner"
                    class="mr-2"
                  />
                  <v-btn v-else icon class="mr-2">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <template v-slot:input>
                    <v-text-field
                      v-model="metric.owner"
                      label="Owner Name"
                      class="mt-6"
                      dense
                      outlined
                      hide-details
                    />
                  </template>
                </v-edit-dialog>
              </td>
              <td class="eos-scorecard__metric-goal">
                <v-edit-dialog large :return-value.sync="metric.goal" @save="onSaveMetric">
                  <span class="eos-scorecard__metric-goal__type">{{ metric.goalType }}</span>

                  <template v-if="metric.dataType === 'Currency'">
                    {{ metric.goal | currency(metric.currencyOptions) }}
                  </template>
                  <template v-else>{{ metric.goal }}</template>

                  <template v-slot:input>
                    <v-select
                      v-model="metric.dataType"
                      :items="['Number', 'Percentage', 'Currency']"
                      label="Goal Type"
                      class="mt-6"
                      dense
                      outlined
                      @change="$analytics('scorecard_changed_goal_type', { type: metric.dataType })"
                    />
                    <v-select
                      v-model="metric.goalType"
                      :items="[
                        { text:'Score is greater than or equal to', value:'>=' },
                        { text:'Score is greater than', value:'>' },
                        { text:'Score is equal to', value:'=' },
                        { text:'Score is less than or equal to', value:'<=' },
                        { text:'Score is less than', value:'<' }
                      ]"
                      label="Success is"
                      dense
                      outlined
                    />
                    <AppInputCurrency
                      v-if="metric.dataType === 'Currency'"
                      v-model="metric.goal"
                      :options="metric.currencyOptions"
                      :prefix="metric.currencyOptions.currency ? '' : metric.currencyOptions.prefix"
                      :suffix="metric.currencyOptions.currency ? '' : metric.currencyOptions.suffix"
                      outlined
                      dense
                      outline
                      autofocus
                      label="Goal"
                    />
                    <v-text-field
                      v-else
                      v-model="metric.goal"
                      label="Goal"
                      type="number"
                      dense
                      outlined
                      autofocus
                      hide-details
                    />

                    <template v-if="metric.dataType === 'Currency'">
                      <p class="mb-3 text-body-2">Currency Settings:</p>
                      <v-select
                        v-model="metric.currencyOptions.locale"
                        :items="availableLocale"
                        label="Locale"
                        dense
                        outlined
                      />
                      <v-select
                        v-model="metric.currencyOptions.currency"
                        :items="availableCurrencies"
                        label="Currency"
                        dense
                        outlined
                        hide-details
                      />

                      <template v-if="!metric.currencyOptions.currency">
                        <v-text-field
                          v-model="metric.currencyOptions.prefix"
                          label="Prefix"
                          class="mt-6"
                          dense
                          outlined
                        />
                        <v-text-field
                          v-model="metric.currencyOptions.suffix"
                          label="Suffix"
                          dense
                          outlined
                          hide-details
                        />
                      </template>
                    </template>
                  </template>
                </v-edit-dialog>
              </td>
              <td
                class="eos-scorecard__metric-aggregate"
                :class="[
                  { 'good' : metric.agreegate && isMetricGood(metric, { score: showRowAgreegateScore(metric) }) },
                  { 'bad' : metric.agreegate && !isMetricGood(metric, { score: showRowAgreegateScore(metric) }) }
                ]"
              >
                <v-edit-dialog large :return-value.sync="metric.agreegate" @save="onSaveMetric">
                  <div class="d-flex align-center">
                    <p v-if="metric.agreegate" class="mb-0">
                      <template v-if="metric.dataType === 'Percentage'">{{ showRowAgreegateScore(metric) }}%</template>
                      <template v-else-if="metric.dataType === 'Currency'">
                        {{ showRowAgreegateScore(metric) | currency(metric.currencyOptions) }}
                      </template>
                      <template v-else>{{ showRowAgreegateScore(metric) }}</template>
                    </p>
                    <span
                      class="text--secondary"
                      :class="{ 'eos-scorecard__metric-agreegate__type' : metric.agreegate }"
                    >
                      {{ metric.agreegate ? `(${metric.agreegate})` : 'Select' }}
                    </span>
                  </div>
                  <template v-slot:input>
                    <v-select
                      v-model="metric.agreegate"
                      :menu-props="{ 'offsetY': true }"
                      :items="['Avg', 'Sum']"
                      label="Summary Type"
                      class="mt-6"
                      dense
                      outlined
                    />
                  </template>
                </v-edit-dialog>
              </td>
            </tr>
            <tr class="eos-scorecard__row eos-scorecard__footer">
              <td colspan="4"><span>Summary</span></td>
            </tr>
          </tbody>
        </template>
      </AppDataTable>

      <AppDataTable
        class="v-data-table v-data-table--fixed-header theme--light eos-scorecard__tbl eos-scorecard__tbl--scroll"
        :class="{
          'eos-scorecard__tbl--shadow-left': showShadow,
          'eos-scorecard__tbl--disabled': !isEditable
        }"
        :items="currentMetrics"
        @pointerenter.native="hoveringOn = 'scoreCols'"
        @pointerleave.native="hoveringOn = null"
      >
        <template v-slot:header>
          <thead class="v-data-table-header">
            <tr>
              <template v-for="(header, index) in headers">
                <th
                  v-if="index > 3 && (showMonthlyScores ? index < 17 : true) && !currHiddenColumns.includes(header.scoreDateIndex)"
                  :key="`tbl-header-${index}`"
                  role="columnheader"
                  scope="col"
                  class="eos-scorecard__header"
                  :class="`text-${header.align || 'center'}`"
                  :aria-label="header"
                >
                  <v-menu
                    v-if="showMonthlyScores ? index === 16 : index === 17"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    max-height="375px"
                    offset-x
                    offset-y
                  >
                   <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">mdi-checkbox-marked</v-icon>
                        </template>
                        <span>Hide Columns</span>
                      </v-tooltip>
                    </template>

                    <v-card>
                      <v-card-title class="text-body-1">Hide Columns</v-card-title>

                      <v-divider></v-divider>

                      <v-list class="pt-0 d-flex flex-column" :class="{ 'flex-column-reverse': !showMonthlyScores }">
                        <template v-for="(header, index) in headers">
                          <v-list-item
                            v-if="index > 3 && (showMonthlyScores ? index < 16 : index < 17)"
                            :key="`show-hide-${index}`"
                          >
                              <v-checkbox
                                v-model="currHiddenColumns"
                                :label="showMonthlyScores && header.scoreDateIndex >= 0 ? monthHeaders[header.scoreDateIndex] : header.text"
                                :value="header.scoreDateIndex"
                                hide-details
                                class="mt-0"
                              />
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </v-menu>

                  <template v-else>
                    <span>
                      {{ showMonthlyScores && header.scoreDateIndex >= 0 ? monthHeaders[header.scoreDateIndex] : header.text }}
                    </span>

                    <div
                      v-if="header.scoreDateIndex >= 0 && !showMonthlyScores"
                      class="d-flex align-center mt-n1 justify-end text-caption eos-scorecard__date--editable"
                    >
                      <AppDatePicker
                        title="Start Date"
                        default-text="From"
                        color="transparent"
                        date-style="short"
                        spacing-class="mx-0 px-0"
                        :date="scoreDates[header.scoreDateIndex].from || ''"
                        @change="setWeekDate($event, header.scoreDateIndex, 'from')"
                      />
                      <span>-</span>

                      <AppDatePicker
                        title="End Date"
                        default-text="To"
                        color="transparent"
                        date-style="short"
                        spacing-class="mx-0 px-0"
                        :date="scoreDates[header.scoreDateIndex].to || ''"
                        @change="setWeekDate($event, header.scoreDateIndex, 'to')"
                      />
                    </div>
                  </template>
                </th>
              </template>
            </tr>
          </thead>
        </template>

        <template v-slot:body="{ items }">
          <draggable
            v-bind="{
              disabled: disableDrag
            }"
            :list="items"
            tag="tbody"
            @change="updateSortOrder"
          >
            <tr
              v-for="metric in items"
              :key="metric.name"
              class="eos-scorecard__row eos-scorecard__metric-row"
            >
              <template v-for="(weekItem, weekNo) in metric.scores">
                <td
                  v-if="!currHiddenColumns.includes(weekNo)"
                  :key="weekNo"
                  :class="[
                    isMetricGood(metric, weekItem) ? 'good' : 'bad',
                    { 'neutral': isNaN(parseFloat(weekItem.score)) }
                  ]"
                  class="eos-scorecard__column__border--bottom"
                >
                  <v-edit-dialog large :return-value.sync="weekItem.score" @save="onSaveMetric">
                    <template v-if="isNaN(parseFloat(weekItem.score))">-</template>
                    <template v-else-if="metric.dataType === 'Percentage'">{{ weekItem.score }}%</template>
                    <template v-else-if="metric.dataType === 'Currency'">
                      {{ weekItem.score | currency(metric.currencyOptions) }}
                    </template>
                    <template v-else>{{ weekItem.score }}</template>
                    <span v-if="!isNaN(parseFloat(weekItem.score))" class="eos-scorecard__metric-goal__percentage">
                      {{ percentAchieved(metric, weekItem) }}%
                    </span>
                    <v-tooltip v-if="weekItem.reason" bottom max-width="300px">
                      <template v-slot:activator="{ on }">
                        <v-icon class="eos-scorecard__metric-goal__info-icon" x-small v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{ weekItem.reason }}</span>
                    </v-tooltip>
                    <template v-slot:input>
                      <AppInputCurrency
                        v-if="metric.dataType === 'Currency'"
                        v-model="weekItem.score"
                        :options="metric.currencyOptions"
                        :prefix="metric.currencyOptions.currency ? '' : metric.currencyOptions.prefix"
                        :suffix="metric.currencyOptions.currency ? '' : metric.currencyOptions.suffix"
                        outlined
                        dense
                        outline
                        autofocus
                        label="Amount"
                        class="mt-5"
                      />
                      <v-text-field
                        v-else
                        v-model="weekItem.score"
                        label="Score"
                        type="number"
                        dense
                        outlined
                        autofocus
                        class="mt-6"
                      />
                      <v-textarea
                        v-model="weekItem.reason"
                        label="Information"
                        rows="3"
                        dense
                        outlined
                        auto-grow
                        hide-details
                      />
                    </template>
                  </v-edit-dialog>
                </td>
              </template>
              <td v-if="isEditable" class="eos-scorecard__metric-actions eos-scorecard__column__border--bottom">
                <v-tooltip v-if="!disableDrag" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon small class="eos-scorecard__metric-actions__drag">
                      <v-icon>mdi-drag</v-icon>
                    </v-btn>
                  </template>
                  <span>Drag</span>
                </v-tooltip>
                <v-tooltip v-if="mode === 'level10'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon x-small class="mr-1" @click="logIssue(metric)">
                      <v-icon>mdi-alert-octagon-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Log an issue</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon x-small @click="deleteMetric(metric)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Metric</span>
                </v-tooltip>
              </td>
            </tr>
          </draggable>
          <tr class="eos-scorecard__row eos-scorecard__footer">
            <template v-for="(weekItem, weekIndex) in currentMetrics[0].scores">
              <td
                v-if="!currHiddenColumns.includes(weekIndex)"
                :key="weekIndex"
              >
                <span v-if="weekPercent(weekIndex) !== null">{{ weekPercent(weekIndex) }}%</span>
                <p v-else class="mb-0 text-center">-</p>
              </td>
            </template>
          </tr>
        </template>
      </AppDataTable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import AppInputCurrency from '@/components/shared/inputs/AppInputCurrency'
import AppDatePicker from '@/components/shared/datepickers/AppDatePicker'
import MONTHS from '@/enums/months'

import { dateToYYYYMMDD } from '@/filters/dateToYYYYMMDD'
import { dateToLocalTZ } from '@/filters/dateToLocalTZ'

const SC_VIEW_WEEKLY = 'Weekly'
const SC_VIEW_MONTHLY = 'Monthly'

export default {
  name: 'EOSScorecard',
  components: {
    draggable,
    AppInputCurrency,
    AppDatePicker
  },
  props: {
    mode: {
      type: String,
      default: 'standalone',
      validator: (value) => ['standalone', 'level10'].indexOf(value) !== -1
    },
    initialMetrics: {
      type: Array,
      default: () => ([])
    },
    initialMonthlyMetrics: {
      type: Array,
      default: () => ([])
    },
    initialScoreDates: {
      type: Array,
      default: () => ([])
    },
    initialView: {
      type: String,
      default: ''
    },
    initialHiddenColumns: {
      type: Object,
      default: () => ({
        weekly: [],
        monthly: []
      })
    },
    currencyOptions: {
      type: Object,
      default: () => ({
        locale: null,
        currency: 'USD',
        prefix: '',
        suffix: ''
      })
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const defaultResetStatuses = {}
    defaultResetStatuses[SC_VIEW_WEEKLY] = false
    defaultResetStatuses[SC_VIEW_MONTHLY] = false

    return {
      availableLocale: [
        { text: 'English (UK)', value: 'en-GB' },
        { text: 'English (USA)', value: 'en-US' },
        { text: 'System Default', value: null }
      ],
      availableCurrencies: [
        { text: 'Custom', value: null },
        { text: 'EUR', value: 'EUR' },
        { text: 'GBP', value: 'GBP' },
        { text: 'USD', value: 'USD' }
      ],
      currentView: SC_VIEW_WEEKLY,
      viewTypes: [
        SC_VIEW_WEEKLY,
        SC_VIEW_MONTHLY
      ],
      resetButtonStatus: defaultResetStatuses,
      headers: [
        {
          text: 'Metric',
          value: 'title',
          align: 'end'
        },
        {
          text: 'Owner',
          value: 'owner',
          align: 'end'
        },
        {
          text: 'Goal',
          value: 'goal',
          align: 'end'
        },
        {
          text: 'Avg. / Sum',
          value: 'avg-sum',
          align: 'end'
        },
        {
          text: 'Week 13',
          value: 'scores[0]',
          align: 'end',
          scoreDateIndex: 0
        },
        {
          text: 'Week 12',
          value: 'scores[1]',
          align: 'end',
          scoreDateIndex: 1
        },
        {
          text: 'Week 11',
          value: 'scores[2]',
          align: 'end',
          scoreDateIndex: 2
        },
        {
          text: 'Week 10',
          value: 'scores[3]',
          align: 'end',
          scoreDateIndex: 3
        },
        {
          text: 'Week 9',
          value: 'scores[4]',
          align: 'end',
          scoreDateIndex: 4
        },
        {
          text: 'Week 8',
          value: 'scores[5]',
          align: 'end',
          scoreDateIndex: 5
        },
        {
          text: 'Week 7',
          value: 'scores[6]',
          align: 'end',
          scoreDateIndex: 6
        },
        {
          text: 'Week 6',
          value: 'scores[7]',
          align: 'end',
          scoreDateIndex: 7
        },
        {
          text: 'Week 5',
          value: 'scores[8]',
          align: 'end',
          scoreDateIndex: 8
        },
        {
          text: 'Week 4',
          value: 'scores[9]',
          align: 'end',
          scoreDateIndex: 9
        },
        {
          text: 'Week 3',
          value: 'scores[10]',
          align: 'end',
          scoreDateIndex: 10
        },
        {
          text: 'Week 2',
          value: 'scores[11]',
          align: 'end',
          scoreDateIndex: 11
        },
        {
          text: 'Current',
          value: 'scores[12]',
          align: 'end',
          scoreDateIndex: 12
        },
        { text: '' }
      ],
      monthHeaders: MONTHS,
      scoreDates: [],
      metrics: [],
      monthlyMetrics: [],
      showShadow: false,
      hiddenColumns: {
        weekly: [],
        monthly: []
      },
      metricsReset: [],
      scoreDatesReset: [],
      monthlyMetricsReset: [],
      hoveringOn: null
    }
  },
  computed: {
    defaultMetric () {
      return Object.assign({}, {
        title: 'New metric',
        dataType: 'Number',
        goal: 50,
        goalType: '>=',
        scores: [
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' },
          { score: null, reason: '' }
        ],
        currencyOptions: Object.assign({}, this.currencyOptions)
      })
    },
    defaultMonthlyMetric () {
      const metric = { ...this.defaultMetric }
      metric.scores = metric.scores.slice()
      metric.scores.splice(0, 1)

      return metric
    },
    showMonthlyScores () {
      return this.currentView === SC_VIEW_MONTHLY
    },
    currentMetrics () {
      return this.showMonthlyScores ? this.monthlyMetrics : this.metrics
    },
    showResetButton () {
      return this.resetButtonStatus[this.currentView] === true
    },
    disableDrag () {
      return this.currentMetrics.length < 2 || !this.isEditable
    },
    currViewLC () {
      return this.currentView.toLowerCase()
    },
    currHiddenColumns: {
      get () {
        return this.hiddenColumns[this.currViewLC]
      },
      set (val) {
        this.hiddenColumns[this.currViewLC] = val
        this.emitMetricUpdate()
        this.$analytics('scorecard_toggled_week_visibility')
      }
    }
  },
  watch: {
    initialMetrics: {
      immediate: true,
      handler (val = []) {
        val = JSON.parse(JSON.stringify(val))
        this.metrics = val.map(metric => this.addCurrencyOptionsToMetric(metric))
      }
    },
    initialMonthlyMetrics: {
      immediate: true,
      handler (val = []) {
        val = JSON.parse(JSON.stringify(val))
        this.monthlyMetrics = val.length > 0 ? val.slice().map(metric => this.addCurrencyOptionsToMetric(metric))
          : [JSON.parse(JSON.stringify(this.defaultMonthlyMetric)), JSON.parse(JSON.stringify(this.defaultMonthlyMetric))]
      }
    },
    initialScoreDates: {
      immediate: true,
      handler (dates = []) {
        const dateCols = this.headers.filter(h => h.scoreDateIndex >= 0)
        dateCols.forEach((col, index) => {
          if (typeof dates[index] === 'object') {
            this.scoreDates[index] = Object.assign({}, dates[index])
          } else {
            const to = dates[index] || ''
            const toDate = to ? new Date(to) : ''
            const fromDate = toDate ? toDate.setDate(toDate.getDate() - 6) : ''
            this.scoreDates[index] = { from: fromDate ? dateToYYYYMMDD(fromDate) : '', to }
          }
        })
      }
    },
    initialView: {
      immediate: true,
      handler (view) {
        this.currentView = view || SC_VIEW_WEEKLY
      }
    },
    initialHiddenColumns: {
      immediate: true,
      handler (data) {
        if (!data.weekly || !data.monthly) return
        this.hiddenColumns = data
      }
    },
    async showMonthlyScores () {
      await this.$nextTick()
      const tbl = document.querySelectorAll('.v-data-table__wrapper')[1]
      if (tbl) this.scrollToLastCol(tbl)
    }
  },
  created () {
    this.metricsReset = JSON.parse(JSON.stringify(this.metrics))
    this.scoreDatesReset = JSON.parse(JSON.stringify(this.scoreDates))
    this.monthlyMetricsReset = JSON.parse(JSON.stringify(this.monthlyMetrics)) // deep clone
  },
  async mounted () {
    await this.$nextTick()
    const tbl = document.querySelectorAll('.v-data-table__wrapper')

    if (tbl[0]) tbl[0].addEventListener('scroll', this.handleStaticColsVerticalScroll)
    if (tbl[1]) {
      tbl[1].addEventListener('scroll', this.handleScoreColsVerticalScroll)
      tbl[1].addEventListener('scroll', this.showHideShadow)
      this.scrollToLastCol(tbl[1])
    }
  },
  beforeDestroy () {
    const tbl = document.querySelectorAll('.v-data-table__wrapper')

    if (tbl[0]) tbl[0].removeEventListener('scroll', this.handleStaticColsVerticalScroll)
    if (tbl[1]) {
      tbl[1].removeEventListener('scroll', this.handleScoreColsVerticalScroll)
      tbl[1].removeEventListener('scroll', this.showHideShadow)
    }
  },
  methods: {
    handleStaticColsVerticalScroll (evt) {
      const top = evt.target.scrollTop
      const tbl = document.querySelectorAll('.v-data-table__wrapper')[1]
      if (!tbl || this.hoveringOn === 'scoreCols') return

      tbl.scrollTop = top
    },
    handleScoreColsVerticalScroll (evt) {
      const top = evt.target.scrollTop
      const tbl = document.querySelectorAll('.v-data-table__wrapper')[0]
      if (!tbl || this.hoveringOn === 'stickyCols') return

      tbl.scrollTop = top
    },
    showHideShadow (evt) {
      this.showShadow = evt.target.scrollLeft > 0
    },
    scrollToLastCol (elm) {
      const { x, width } = elm.getBoundingClientRect()
      elm.scrollLeft = x + width
    },
    addCurrencyOptionsToMetric (metric) {
      const metricCurrencyOptions = metric.currencyOptions || {}
      let scores = metric.scores || []

      if (scores.length === 0 && metric.weekScores) {
        scores = metric.weekScores.slice()
        // TODO: delete metric.weekScores
      }

      return {
        ...metric,
        description: metric.description || '',
        agreegate: metric.agreegate || '',
        scores,
        currencyOptions: { ...this.currencyOptions, ...metricCurrencyOptions }
      }
    },
    isMetricGood (metric, scores) {
      metric.goal = parseFloat(metric.goal)
      const score = parseFloat(scores.score)
      switch (metric.goalType) {
        case '<':
          if (score >= metric.goal) return false
          break
        case '<=':
          if (score > metric.goal) return false
          break
        case '=':
          if (score !== metric.goal) return false
          break
        case '>':
          if (score <= metric.goal) return false
          break
        default:
          if (score < metric.goal) return false
      }
      return true
    },
    percentAchieved (metric, weekItem) {
      if (isNaN(parseFloat(weekItem.score))) return null
      if (this.isMetricGood(metric, weekItem)) return 100

      metric.goal = parseFloat(metric.goal)
      let score = parseFloat(weekItem.score)
      if (metric.goal === 0) {
        return score === 0 ? 100 : 0
      } else if (metric.goalType === '=') {
        return (score !== metric.goal) ? 0 : 100
      }

      let pc = 100
      if (metric.goalType === '<=' || metric.goalType === '<') {
        if (score <= metric.goal) {
          pc = (Math.max(score, 0) / metric.goal) * 100
        } else {
          score -= metric.goal
          pc = 100 - (Math.min(score, metric.goal) / metric.goal) * 100
        }
      } else {
        if (score <= metric.goal) {
          pc = (Math.max(score, 0) / metric.goal) * 100
        } else {
          pc = (Math.min(score, metric.goal) / metric.goal) * 100
        }
      }
      return Math.ceil(pc * 100) / 100
    },
    weekPercent (weekIndex) {
      let hasScore = false
      let total = 0

      for (let m = 0; m < this.currentMetrics.length; m++) {
        const metric = this.currentMetrics[m]
        const weekItem = metric.scores[weekIndex]
        const score = this.percentAchieved(metric, weekItem)
        hasScore = hasScore || score !== null
        total += score
      }

      return hasScore ? Math.ceil((total / this.currentMetrics.length) * 100) / 100 : null
    },
    resetMetrics () {
      if (this.showMonthlyScores) {
        this.monthlyMetrics = JSON.parse(JSON.stringify(this.monthlyMetricsReset))
      } else {
        this.metrics = JSON.parse(JSON.stringify(this.metricsReset))
        this.scoreDates = JSON.parse(JSON.stringify(this.scoreDatesReset))
      }

      this.resetButtonStatus[this.currentView] = false
      this.emitMetricUpdate()
      this.$analytics('scorecard_reset_metric')
    },
    addMetric () {
      this.showMonthlyScores ? this.monthlyMetrics.push({ ...this.defaultMonthlyMetric }) : this.metrics.push({ ...this.defaultMetric })
      this.resetButtonStatus[this.currentView] = true
      this.emitMetricUpdate()
      this.$analytics('scorecard_added_metric')
    },
    addWeek () {
      const metrics = this.showMonthlyScores ? this.monthlyMetrics : this.metrics

      for (let i = 0; i < metrics.length; i++) {
        const metric = metrics[i]
        const scores = metric.scores
        const lastScore = scores[scores.length - 1].score
        scores.shift()
        scores.push({ score: lastScore, reason: '' })
      }

      // update scorecard dates if it's weekly
      if (!this.showMonthlyScores && this.scoreDates[this.scoreDates.length - 1].to) {
        const lastDate = dateToLocalTZ(new Date(this.scoreDates[this.scoreDates.length - 1].to))
        const nextColFrom = dateToYYYYMMDD(lastDate.setDate(lastDate.getDate() + 1))
        const nextColTo = dateToYYYYMMDD(lastDate.setDate(lastDate.getDate() + 6))

        this.scoreDates.shift()
        this.scoreDates.push({ from: nextColFrom, to: nextColTo })
      }

      this.resetButtonStatus[this.currentView] = true
      this.emitMetricUpdate()
      this.$analytics('scorecard_added_week')
    },
    deleteMetric (metric) {
      const metrics = this.showMonthlyScores ? this.monthlyMetrics : this.metrics
      const pos = metrics.indexOf(metric)
      if (pos > -1) {
        metrics.splice(pos, 1)
      }
      this.emitMetricUpdate()
      this.$analytics('scorecard_deleted_metric')
    },
    logIssue (metric) {
      const lastWeekScore = metric.scores[metric.scores.length - 1]
      const issue = `Scorecard metric '${metric.title}' off track (${lastWeekScore.score} is not ${metric.goalType} ${metric.goal})`
      this.$emit('add-ids-issue', issue)

      const text = 'Issue logged'
      if (lastWeekScore.reason.indexOf(text) === -1) {
        if (lastWeekScore.reason !== '') lastWeekScore.reason += '\n'
        lastWeekScore.reason += text
      }
      this.emitMetricUpdate()
      this.$analytics('scorecard_logged_issue')
    },
    async setWeekDate (date, index, field) {
      // set value of changed field
      this.$set(this.scoreDates[index], field, date)

      // check other field is set
      const otherField = field === 'to' ? 'from' : 'to'
      const selectedDateInLocalTZ = dateToLocalTZ(date)
      const daysDiff = otherField === 'to' ? 6 : -6
      const otherDate = dateToYYYYMMDD(selectedDateInLocalTZ.setDate(selectedDateInLocalTZ.getDate() + daysDiff))

      // set other field accordingly
      this.$set(this.scoreDates[index], otherField, otherDate)

      // auto calculate for other weeks
      this.autoUpdateColumnDates(index)

      await this.$nextTick()
      this.emitMetricUpdate()
      this.$analytics('scorecard_updated_week_date')
    },
    autoUpdateColumnDates (refWeekIndex) {
      // left columns
      for (let i = refWeekIndex - 1; i >= 0; i--) {
        const from = dateToLocalTZ(this.scoreDates[i + 1].from)
        const dlTo = this.scoreDates[i].to || dateToYYYYMMDD(from.setDate(from.getDate() - 1))
        const dlFrom = this.scoreDates[i].from || dateToYYYYMMDD(from.setDate(from.getDate() - 6))

        this.$set(this.scoreDates, i, { to: dlTo, from: dlFrom })
      }

      // right columns
      for (let i = refWeekIndex + 1; i <= 12; i++) {
        const to = dateToLocalTZ(this.scoreDates[i - 1].to)
        const drFrom = this.scoreDates[i].from || dateToYYYYMMDD(to.setDate(to.getDate() + 1))
        const drTo = this.scoreDates[i].to || dateToYYYYMMDD(to.setDate(to.getDate() + 6))

        this.$set(this.scoreDates, i, { from: drFrom, to: drTo })
      }
    },
    onSaveMetric () {
      this.emitMetricUpdate()
      this.$emit('set-message', { type: 'success', message: 'Data saved!' })
    },
    optimizeMetric (metric) {
      const metricCopy = Object.assign({}, metric)
      const changedCurrencyOptions = {}

      // Remove default options to save space on firebase
      Object.keys(metricCopy.currencyOptions).forEach(option => {
        const currentOption = metricCopy.currencyOptions[option]
        if (currentOption === this.currencyOptions[option]) return
        changedCurrencyOptions[option] = currentOption
      })

      // Remove prefix/suffix if not 'Custom'
      if (changedCurrencyOptions.currency) {
        delete changedCurrencyOptions.prefix
        delete changedCurrencyOptions.suffix
      }
      // no need to save if it's custom and no prefix/suffix is provided
      if (!changedCurrencyOptions.currency && !changedCurrencyOptions.prefix && !changedCurrencyOptions.suffix) {
        delete changedCurrencyOptions.currency
      }

      // If nothing has changed save without currencyOptions or save only changed ones
      Object.keys(changedCurrencyOptions).length > 0 ? metricCopy.currencyOptions = changedCurrencyOptions
        : delete metricCopy.currencyOptions

      return metricCopy
    },
    emitMetricUpdate () {
      const metrics = this.metrics.slice().map(metric => this.optimizeMetric(metric))
      const monthlyMetrics = this.monthlyMetrics.slice().map(metric => this.optimizeMetric(metric))

      this.$emit('metric-update', {
        metrics,
        monthlyMetrics,
        scoreDates: this.scoreDates,
        hiddenColumns: this.hiddenColumns
      })
    },
    updateSortOrder ({ moved }) {
      const arr = this.showMonthlyScores ? this.monthlyMetrics : this.metrics
      const element = arr[moved.oldIndex]

      arr.splice(moved.oldIndex, 1)
      arr.splice(moved.newIndex, 0, element)

      this.emitMetricUpdate()
      this.$analytics('scorecard_reordered_metrics')
    },
    showRowAgreegateScore (metric) {
      let visibleColumns = 0
      const sum = metric.scores.reduce((prevVal, currVal, weekNo) => {
        const isHidden = this.currHiddenColumns.includes(weekNo)
        const val = parseFloat(currVal.score)
        // consider columns with values and visible
        if (!isNaN(val) && !isHidden) {
          visibleColumns += 1
          return (prevVal + val)
        }
        return prevVal
      }, 0)
      return (metric.agreegate || '').toLowerCase() === 'avg' ? parseFloat(sum / visibleColumns).toFixed(2) : sum
    },
    exportToCSV () {
      const headers = document.querySelectorAll('.eos-scorecard__header')
      const tbodies = document.querySelectorAll('tbody')
      if (headers.length === 0 || tbodies.length === 0) return

      let str = ''
      let line = ''
      headers.forEach(header => {
        if (line !== '') line += ','
        const data = header.innerText.split('\n')
        line += data[0]
        if (data[1]) line += ` (${data[1]} ${data[2]} ${data[3]})`
      })
      str += line.replace(/.$/, '') + '\r\n'

      const allRows = document.querySelectorAll('.eos-scorecard__row')
      const numOfRows = allRows.length / 2

      const formatCell = (td) => {
        let value = ''
        const data = (td.innerText || '').split('\n')

        value += data[0].replaceAll(',', '')
        // if (data[1]) value += ` (${data[1].replace(',', '')})`
        return value
      }

      for (let i = 0; i < numOfRows; i++) {
        line = ''
        allRows[i].childNodes.forEach(td => {
          if (line !== '') line += ','
          if (formatCell(td) === 'Summary') line += ',,,'
          line += formatCell(td)
        })
        allRows[numOfRows + i].childNodes.forEach(td => {
          line += ','
          line += formatCell(td)
        })
        str += line.replace(/.$/, '') + '\r\n'
      }

      const exportedFilenmae = 'export.csv'
      const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' })

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFilenmae)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      this.$analytics('scorecard_exported_csv')
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-scorecard__toolbar--standalone {
  height: 48px;
  position: sticky;
  top: 48px;
  z-index: 6;
}

.eos-scorcard__tbl-wrapper {
  overflow-x: hidden;
}

::v-deep .eos-scorecard__tbl--no-scroll {
  width: auto !important;
  z-index: 1;
  border-radius: 0px;

  &:not(.eos-scorecard__tbl--shadow-right) {
    border-right: 1px solid #eee;
  }

  .v-data-table__wrapper {
    overflow-x: hidden;
    padding-bottom: 15px;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .v-data-table__wrapper::-webkit-scrollbar {
    display: none;
  }
}

::v-deep .eos-scorecard__tbl--scroll {
  overflow-x: auto;
  z-index: 0;
}

::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 105px);
}

.eos-scorcard__tbl-wrapper--level10 {
  ::v-deep .v-data-table__wrapper {
    max-height: calc(100vh - 200px);
  }
}

.eos-scorecard__tbl--shadow-right {
  box-shadow: 7px 1px 5px -5px rgba(0, 0, 0, 0.4);
}

.eos-scorecard__tbl--shadow-left {
  border-radius: 0px;
}

::v-deep .v-data-table {
  thead {
    tr {
      th {
        font-size: 10px;
        text-align: right !important;
        padding: 0 15px 5px 0 !important;
        margin: 0 !important;
        height: auto;
        text-transform: uppercase;

        span {
          white-space: nowrap;
          color: rgb(20, 97, 212);
          font-weight: normal;
        }
      }
    }
  }
}

.eos-scorecard__tbl {
  margin: 10px 0 0 0;
}

.eos-scorecard__view-type {
  max-width: 120px;

  ::v-deep .v-select__selection {
    margin-right: 0px;
  }

  ::v-deep .v-input__slot {
    padding: 0px 14px !important;
  }
}

.eos-scorecard__metric-row {
  td {
    position: relative;
    text-align: right;
    white-space: nowrap;

    .eos-scorecard__metric-goal__percentage,
    .eos-scorecard__metric-agreegate__type {
      font-size: 8px;
      position: absolute;
      right: 15px;
      bottom: 5px;
      color: #888;
    }
  }

  td.good:not(.neutral) {
    background-color: rgb(200, 220, 200);

    &:hover {
      background-color: rgb(200, 240, 200) !important;
    }
  }

  td.bad:not(.neutral) {
    background-color: rgb(220, 200, 200);

    &:hover {
      background-color: rgb(240, 200, 200) !important;
    }
  }

  td.neutral {
    text-align: center;
  }

  td.eos-scorecard__column__border--bottom {
    border-bottom: 1px solid #e2e2e2;
  }
}

.eos-scorecard__date--editable {
  ::v-deep .v-chip {
    border-bottom: 1px dotted #000 !important;
    border-radius: 0px;
  }
}

.eos-scorecard__metric-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.eos-scorecard__metric-goal {
  color: #444;
  font-weight: bold;
  text-align: right;
}

.eos-scorecard__metric-goal__type {
  font-size: 70%;
  color: #999;
}

.eos-scorecard__metric-goal__info-icon {
  font-size: 8px;
  position: absolute;
  right: 3px;
  top: 50%;
  color: #888;
  margin-top: -6px;
}

.eos-scorecard__metric-actions {
  text-align: right;
  border: none;
  width: 40px;
  padding: 0;

  .eos-scorecard__metric-actions__drag {
    cursor: move;
  }
}

.eos-scorecard__footer {
  td {
    color: #999;
    font-size: 12px;
    text-align: right;
    padding: 15px 10px;
  }
}

::v-deep .v-data-table-header-mobile__wrapper {
  margin-left: 10px;
}

::v-deep .v-chip__content {
  color: #424242 !important;
}

.eos-scorecard__tbl--disabled {
  th:last-child {
    display: none;
  }

  ::v-deep .v-small-dialog__activator {
    pointer-events: none;
  }

  ::v-deep .eos-scorecard__metric-goal__info-icon {
    pointer-events: all;
  }
}
</style>
