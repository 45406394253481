<template>
  <v-text-field
    v-model="displayValue"
    v-bind="$attrs"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
  />
</template>

<script>
export default {
  name: 'AppInputCurrency',
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isInputActive: false
    }
  },
  computed: {
    displayValue: {
      get () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return typeof this.value === 'number' ? this.value.toString() : this.value
        } else {
          // User is not modifying now. Format display value for user interface
          return this.$options.filters.currency(this.value, {
            ...this.options,
            noSymbol: !this.options.currency && (this.options.prefix || this.options.suffix)
          })
        }
      },
      set (modifiedValue) {
        // Recalculate value after ignoring "currency symbol" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.-]/g, ''))
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
